import React from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import InputGroup from "react-bootstrap/InputGroup";
import Form from "react-bootstrap/Form";
import images from "./images";
import Button from "react-bootstrap/Button";
import axios from "axios";
import config from "./config";
import { useState } from 'react';
import { useEffect } from 'react';
import { HashLink } from 'react-router-hash-link';
import toast, { Toaster }  from 'react-hot-toast'



const sleep = (ms) => new Promise(resolve => setTimeout(resolve, ms))

const Footer = () => {

  const [records, set_records] = useState([]);
  const [Social, set_Social] =  useState([]);
  const [Subscribers,set_subscribers] = useState("");

  //venkatesh
  const [error, setError] = useState({});
  console.log('errpr_data',error)

  const getData = async () => {
    console.log("data")
    try {
      console.log("data")
        axios
            .get(config.backurl + "/cms-content/getfooter")
            .then(res => {
                console.log("footer", res)
                set_records(res.data.data[0])
                console.log("records",records)           
            })
            .catch()
    }
    catch (err) {
  
    }
  };

  const getSocial = async () => {
    console.log("data")
    try {
      console.log("data")
        axios
            .get(config.backurl + "/cms-content/getcms")
            .then(res => {
                console.log("res", res)
                var social = res.data.data.filter(items=>items.SectionId==="Social Media Links")
                set_Social(social[0])          
            })
            .catch()
    }
    catch (err) {
  
    }
  };

  useEffect(() => {
    getData();
    getSocial();
 }, [])



 const onChangefun = (e) => {

  if (e.target.id == 'subscribers')
  set_subscribers(e.target.value)

};



  const ValidateEmail = (email) => {
    let regex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/
    let error = {}
    if (email != "") {
      if (!regex.test(email)) {
        error.email = "You have entered an invalid email address!"
      }
    } else {
      error.email = "Email is required!"
    }
    setError(error)
    return error
  }


  const subs = async () => {
    console.log("subscribers")
    try {
      let validates = await ValidateEmail(Subscribers);
      let objlen = Object.values(validates).length
      if (objlen == 0) {
        var subsc = {
          Subscribers: Subscribers
        }
        axios
          .post(config.backurl + "/cms-content/subscribers", subsc)
          .then(async (res) => {
            console.log("respond_data", res,res.data.status == true,res.data.message)
            if (res.status === 200 && res.data.status == true) {
              console.log("toast_1 jeje")
              toast.success(`${res.data.message}`, { style: { minWidth: '300px', minHeight: '55px' } })
              await sleep(1000)
              window.location.reload()
            } else {
              console.log("toast_2 jeje")
              toast.error(`${res.data.message}`, { style: { minWidth: '300px', minHeight: '55px' } })
              // await sleep(1000)
            }
          })
          .catch()
      }


    }
    catch (err) {
      console.log("subscribers_err", err)
    }

  };


  

  return (
    <footer className="footer">
      <div className="footerbox">
      <div><Toaster/></div> 
        <Container>
          <Row>
            <Col xl={4} lg={4} md={8} sm={8} xs={12}>
              <img src={images.logo} alt="" className="footlogo" />
              {records && records.deleted ===1 ? 
              <>
              <p className="footcontent">
                {records.FooterContent}
                {/* Invest in FindAMill and be part of our mission to connect
                traders with a million opportunities in the crypto market.
                Together, we can revolutionize the way people trade and achieve
                their financial goals. */}
              </p>
              </>:""}
            </Col>
            <Col xl={2} lg={2} md={4} sm={4} xs={12}>
              <h5 className="foothead">Menu</h5>
              <ul className="footlist">
                <li>
                <HashLink smooth to="/#Home">Home</HashLink>
                </li>
                <li>
                <HashLink smooth to="/#AboutUs">About Us</HashLink>
                </li>
                <li>
                <HashLink smooth to="/#Roadmap">Roadmap</HashLink>
                </li>
                <li>
                <HashLink smooth to="/#ContactUs">Contact Us</HashLink>
                </li>
              </ul>
            </Col>
            <Col xl={6} lg={6} md={12} sm={12} xs={12}>
              <div className="emailbox">
              {records && records.deleted ===1 ? 
                <h5 className="h5">{records.EmailBox}
                  {/* Join us on this exciting journey! */}
                  </h5>:""}
                <InputGroup>
                  <Form.Control placeholder="Enter your email id" id="subscribers" onChange={(e)=>{onChangefun(e)}}></Form.Control>
                  <InputGroup.Text className="subs" onClick={()=>{subs()}}>Subscribe</InputGroup.Text>
                </InputGroup>
                <span className="text-danger">{error.email}</span>
                <div className="socialmedia" id="ContactUs">
                  <div>
                    <span>Our Social media links</span>
                  </div>
                  <div>
                    <a href={Social && Social.Tag1} target='_blank'>
                      <img src={images.whatsapp} alt="" />
                    </a>
                    <a href={Social && Social.Tag2} target='_blank'>
                      <img src={images.telegram} alt="" />
                    </a>
                    <a href={Social && Social.Tag3} target="_blank">
                      <img src={images.gmail} alt="" />
                    </a>
                    <a href={Social && Social.Tag4} target="_blank">
                      <img src={images.twitter} alt="" />
                    </a>

                  </div>
                </div>
              </div>
            </Col>
          </Row>
          <hr className="hrline" />
          <div className="copyrights">
            <p className="copytext">Copyrights@2023. All Rights are reserved</p>
          </div>
          <img src={images.workleftimg} alt="" className="workleftimg" />
          <img src={images.adrightimg} alt="" className="rightimg" />
        </Container>
      </div>
    </footer>
  );
};

export default Footer;
