import React, { useEffect, useState } from "react";
import Nav from "react-bootstrap/Nav";
import Container from "react-bootstrap/Container";
import Navbar from "react-bootstrap/Navbar";
import images from "./images";
import Button from "react-bootstrap/Button";
import { Link } from "react-router-dom";
import { HashLink } from 'react-router-hash-link';

const Header = () => {
  const [scroll, setScroll] = useState("");

  useEffect(() => {
    window.addEventListener("scroll", () => {
      setScroll(window.scrollY > 200);
    });
  }, []);

  // useEffect(() => {
  //   window.scrollTo(0, 0);
  //   document.body.scrollTop = 0;
  //   const href = window.location.href.substring(
  //       window.location.href.lastIndexOf('#') + 1,
  //   );
  //   if (window.location.href.lastIndexOf('#') > 0) {
  //       document.getElementById(href)?.scrollIntoView();
  //   }
  // }, []);
  

  return (
    <Navbar
      expand="md"
      fixed="top"
      variant="dark"
      className={scroll ? "scrolltop" : ""}
    >
      <Container>
        <Navbar.Brand>
          <Link to="/">
            <img src={images.logo} alt="" className="logo" />
          </Link>
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="topnav"></Navbar.Toggle>
        <Navbar.Collapse id="topnav">
          <Nav className="mx-auto">
          <HashLink className="nav-link" smooth to="/#Home">Home</HashLink>
            {/* <Nav.Link href="#">About Us</Nav.Link>
            <Nav.Link href="#">Roadmap</Nav.Link>
            <Nav.Link href="#">Contact Us</Nav.Link> */}
            <HashLink className="nav-link" smooth to="/#AboutUs">About Us</HashLink>
            <HashLink className="nav-link" smooth to="/#Roadmap">Roadmap</HashLink>
            <HashLink className="nav-link" smooth to="/#ContactUs">Contact Us</HashLink>
          </Nav>
          <Nav>
            <Nav.Link className="headlastbtn">
              <Button variant="primary" className="headbtn">
                <span>
                  Lorem
                  <img src={images.arrow} alt="" />
                </span>
              </Button>
            </Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default Header;
