import React from "react";
import Header from "./Header";
import Container from "react-bootstrap/Container";
import Button from "react-bootstrap/Button";
import images from "./images";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Footer from "./Footer";
import axios from "axios";
import config from "./config";
import { useState } from 'react';
import { useEffect } from 'react';
import { HashLink } from 'react-router-hash-link';
const Home = () => {

const [records, set_records] = useState([]);
const [market, set_market] = useState([]);
const [whyfindamill,set_whyfindamill] = useState([]);
const [competition,set_competition] = useState([]);
const [advantages, set_advantages] = useState([]);
const [HowItWorks, set_HowItWorks] = useState([]);
const [business, set_business] = useState([]);
const [investment, set_investment] = useState([]);
const [possibilities, set_possibilities] = useState([]);
const [team , set_Team] = useState([]);
const [conclusion , set_conclusion] = useState([]);
const [home, set_home] = useState([]);
const [roadmap , set_roadmap] = useState([]);

const getData = async () => {
  console.log("data")
  try {
    console.log("data")
      axios
          .get(config.backurl + "/cms-content/getcms")
          .then(res => {
              console.log("res", res)
              var aboutUs = res.data.data.filter(items=>items.SectionId==="Problem That We Are Solving")
              set_records(aboutUs[0])
              console.log("records",records)
              var Market = res.data.data.filter(items=>items.SectionId==="Addressing The Need In The")
              set_market(Market[0])
              console.log("market",market)
              var WhyFind = res.data.data.filter(items=>items.SectionId==="Why FindAMill?")
              set_whyfindamill(WhyFind[0])
              console.log("whyfindamill",whyfindamill)
              var Comp = res.data.data.filter(items=>items.SectionId==="Competition")
              set_competition(Comp[0])
              console.log("competition",competition)
              var adv = res.data.data.filter(items=>items.SectionId==="Advantages")
              set_advantages(adv[0])
              console.log("advantages",advantages)
              var howItWorks = res.data.data.filter(items=>items.SectionId==="How It Works")
              set_HowItWorks(howItWorks[0])
              console.log("HowItWorks",HowItWorks)
              var businessModel = res.data.data.filter(items=>items.SectionId==="Business Model")
              set_business(businessModel[0])
              console.log("business",business)
              var investmentModel = res.data.data.filter(items=>items.SectionId==="Investment That We Looking For")
              set_investment(investmentModel[0])
              console.log("investment",investment)
              var Possibilities = res.data.data.filter(items=>items.SectionId==="Investor Exit Possibilities")
              set_possibilities(Possibilities[0])
              console.log("possibilities",possibilities)
              var OurTeam = res.data.data.filter(items=>items.SectionId==="Our Team")
              set_Team(OurTeam[0])
              console.log("team",team)
              var Conclusion = res.data.data.filter(items=>items.SectionId==="Conclusion")
              set_conclusion(Conclusion[0])
              console.log("conclusion",conclusion)              
          })
          .catch()
  }
  catch (err) {

  }
};

const gethomeData = async () => {
  console.log("data")
  try {
      axios
          .get(config.backurl + "/cms-content/gethomepage")
          .then(res => {
              console.log("res", res)
              set_home(res.data.data[0])

          })
          .catch()
  }
  catch (err) {

  }
};

const getRoadmapData = async () => {
  console.log("data")
  try {
      axios
          .get(config.backurl + "/cms-content/getFindAMill")
          .then(res => {
              console.log("roadmap", res)
              set_roadmap(res.data.data[0])

          })
          .catch()
  }
  catch (err) {

  }
};


useEffect(() => {
  getData();
  gethomeData();
  getRoadmapData();


}, [])

useEffect(() => {
  window.scrollTo(0, 0);
  document.body.scrollTop = 0;
  const href = window.location.href.substring(
      window.location.href.lastIndexOf('#') + 1,
  );
  if (window.location.href.lastIndexOf('#') > 0) {
      document.getElementById(href)?.scrollIntoView();
  }
}, []);

  return (
    <>
      <article className="toppage">

        <Header />

        <section className="banner">
          
          <Container>
            <div className="bannerbox" id="Home" >
              {home && home.deleted===1 ?
              <>
              <h1 className="h1">{home.homeTitle}</h1>
              <h6 className="h6">{home.homeHeading}</h6>
              <div className="bannerbutton">
                <Button variant="primary" className="bannerbtn">
                <HashLink smooth to="/#AboutUs">  <span>{home.homeButton}</span></HashLink>
               
                </Button>
                </div>
              </>            
              :""}
              <div>
                <img src={images.bannerbtc} alt="" className="btc" />
                <img src={images.bannerpolygon} alt="" className="polygon" />
                <img src={images.bannertron} alt="" className="tron" />
                <img src={images.bannerstar} alt="" className="star" />
                <img src={images.bannerspheres} alt="" className="spheres" />
                <img src={images.bannerbinance} alt="" className="binance" />
                <img src={images.bannertether} alt="" className="tether" />
                <img src={images.bannereth} alt="" className="eth" />
              </div>
            </div>
          </Container>
        </section>

        <div className="twoimages">
          <img src={images.bannerspheres} alt="" className="spheresone" />
          <img src={images.bannerpentagram} alt="" className="pentagram" />
        </div>

        <section className="problem"id="AboutUs">
          <Container>
            {records && records.deleted ===1 ?
            <>
            <h2 className="headtitle">{records.SectionId}</h2>
            <h5 className="subhead">
              {records.SubHeading ? records?.SubHeading[0]?.Heading1:""} 
             
            </h5>
            <div className="problembox">
              <Row>
                <Col xl={3} lg={3} md={6} sm={6} xs={12}>
                  <div className="proimg">
                    <img src={`${config.Img}Images/cms/${records.Tag1}`} alt="" />
                  </div>
                  <div className="probox">
                    <p className="procontent">{records.Content? records?.Content[0]?.Content1:""} </p>
                  </div>
                </Col>
                <Col xl={3} lg={3} md={6} sm={6} xs={12}>
                  <div className="proimg">
                    <img src={`${config.Img}Images/cms/${records.Tag2}`} alt="" />
                  </div>
                  <div className="probox">
                    <p className="procontent">
                    {records.Content? records?.Content[1]?.Content2:""} 
                    </p>
                  </div>
                </Col>
                <Col xl={3} lg={3} md={6} sm={6} xs={12}>
                  <div className="proimg">
                    <img src={`${config.Img}Images/cms/${records.Tag3}`} alt="" />
                  </div>
                  <div className="probox">
                    <p className="procontent">
                    {records.Content? records?.Content[2]?.Content3:""} 
                    </p>
                  </div>
                </Col>
                <Col xl={3} lg={3} md={6} sm={6} xs={12}>
                  <div className="proimg">
                    <img src={`${config.Img}Images/cms/${records.Tag4}`} alt="" />
                  </div>
                  <div className="probox">
                    <p className="procontent">
                    {records.Content? records?.Content[3]?.Content4:""} 
                    </p>
                  </div>
                </Col>
              </Row>
           
              <img src={images.leftboximg} alt="" className="leftboximg" />
              <img src={images.bannerstar} alt="" className="starone" />
            </div>
            </>
            :""}
          </Container>
        </section>

        <section className="address">
          <Container>
            {market && market.deleted ===1 ?
            <>
            <h2 className="headtitle">
            {market.SectionId} <br />   {market.SubHeading ? market?.SubHeading[0]?.Heading1:""} 
            </h2>
            <h5 className="subhead"> {market.SubHeading ? market?.SubHeading[1]?.Heading2:""} </h5>
            <img src={images.bannerbinance} alt="" className="binanceone" />
            <img src={images.bannerspheres} alt="" className="spherestwo" />

            <div className="bluebox">
              <Row>
                <Col xl={3} lg={3} md={6} sm={6} xs={12}>
                  <div className="bluesubox">
                    <h5 className="h5">{market.Tag1}</h5>
                    <p className="blueboxcontent">
                    {market.Content? market?.Content[0]?.Content1:""} 
                    </p>
                  </div>
                </Col>
                <Col xl={3} lg={3} md={6} sm={6} xs={12}>
                  <div className="bluesubox">
                    <h5 className="h5">{market.Tag2}</h5>
                    <p className="blueboxcontent">
                    {market.Content? market?.Content[1]?.Content2:""} 
                    </p>
                  </div>
                </Col>
                <Col xl={3} lg={3} md={6} sm={6} xs={12}>
                  <div className="bluesubox">
                    <h5 className="h5">{market.Tag3}</h5>
                    <p className="blueboxcontent">
                    {market.Content? market?.Content[2]?.Content3:""} 
                    </p>
                  </div>
                </Col>
                <Col xl={3} lg={3} md={6} sm={6} xs={12}>
                  <div className="bluesubox">
                    <h5 className="h5">{market.Tag4}</h5>
                    <p className="blueboxcontent">
                    {market.Content? market?.Content[3]?.Content4:""}  <br />  {market.Content? market?.Content[4]?.Content5:""} 
                    </p>
                  </div>
                </Col>
              </Row>
            </div>
            </>
            :""}
          </Container>
        </section>

        <section className="whyfindamill">
          <Container>
          {whyfindamill && whyfindamill.deleted ===1 ?
            <>
            <div className="millparent">
              <h2 className="headtitle">{whyfindamill.SectionId}</h2>
              <Row>
                <Col xl={6} lg={6} md={6} sm={12} xs={12}>
                  <div className="text-center">
                    <img src={`${config.Img}Images/cms/${whyfindamill.Tag1}`}  alt="" className="millimg" />
                  </div>
                </Col>
                <Col xl={6} lg={6} md={6} sm={12} xs={12}>
                  <ul className="millist">
                    <li>
                      <p className="millcontent">
                      {whyfindamill.Content? whyfindamill?.Content[0]?.Content1:""}
                      </p>
                    </li>
                    <li>
                      <p className="millcontent">
                      {whyfindamill.Content? whyfindamill?.Content[1]?.Content2:""}
                      </p>
                    </li>
                    <li>
                      <p className="millcontent">
                      {whyfindamill.Content? whyfindamill?.Content[2]?.Content3:""}
                      </p>
                    </li>
                    <li>
                      <p className="millcontent">
                      {whyfindamill.Content? whyfindamill?.Content[3]?.Content4:""}
                      </p>
                    </li>
                  </ul>
                </Col>
              </Row>
              <img src={images.bannertron} alt="" className="tronone" />
              <img src={images.millstar} alt="" className="millstar" />
              <img src={images.bannerbtc} alt="" className="btcone" />
              <img src={images.millrightimg} alt="" className="millrightimg" />
            </div>
            </>
            :""}
          </Container>
          <img src={images.Picture2} alt="" className="picturetwo" />
        </section>

        <section className="competition">
          <Container>
          {competition && competition.deleted ===1 ? 
          <>
            <h2 className="headtitle">{competition.SectionId}</h2>
            <div className="compet">
              <div className="competbox">
                <h5 className="h5">  {competition.SubHeading ? competition?.SubHeading[0]?.Heading1:""} </h5>
                <ul className="competlist">
                  <li> {competition.Content? competition?.Content[0]?.Content1:""}</li>
                  <li>{competition.Content? competition?.Content[1]?.Content2:""}</li>
                  <li>{competition.Content? competition?.Content[2]?.Content3:""}</li>
                </ul>
              </div>
              <div className="competbox">
                <h5 className="h5"> {competition.SubHeading ? competition?.SubHeading[1]?.Heading2:""} </h5>
                <ul className="competlist">
                  <li>{competition.Content? competition?.Content[3]?.Content4:""}</li>
                  <li>{competition.Content? competition?.Content[4]?.Content5:""}</li>
                  <li>{competition.Content? competition?.Content[5]?.Content6:""}</li>
                </ul>
              </div>
              <img src={`${config.Img}Images/cms/${competition.Tag1}`}  alt="" className="rotatecoin" />
            </div>
            <div className="competcontent">
              <p className="paracontent">
              {competition.Tag2}
              </p>
            </div>
            </>
            :""}
          </Container>
        </section>

        <section className="advantages">
          <Container>
          {advantages && advantages.deleted ===1 ?  
          <>
            <h2 className="headtitle">{advantages.SectionId}</h2>
            <h5 className="subhead">{advantages.SubHeading ? advantages?.SubHeading[0]?.Heading1:""} </h5>
            <div className="adparentbox">
              <h2 className="h2">{advantages.SubHeading ? advantages?.SubHeading[1]?.Heading2:""}</h2>
              <Row>
                <Col xl={3} lg={3} md={6} sm={6} xs={12}>
                  <div className="adbox">
                    <p className="adcontent">
                    {advantages.Content? advantages?.Content[0]?.Content1:""}
                    </p>
                  </div>
                </Col>
                <Col xl={3} lg={3} md={6} sm={6} xs={12}>
                  <div className="adbox">
                    <p className="adcontent">
                    {advantages.Content? advantages?.Content[1]?.Content2:""}
                    </p>
                  </div>
                </Col>
                <Col xl={3} lg={3} md={6} sm={6} xs={12}>
                  <div className="adbox">
                    <p className="adcontent">
                    {advantages.Content? advantages?.Content[2]?.Content3:""}
                    </p>
                  </div>
                </Col>
                <Col xl={3} lg={3} md={6} sm={6} xs={12}>
                  <div className="adbox">
                    <p className="adcontent">
                    {advantages.Content? advantages?.Content[3]?.Content4:""}
                    </p>
                  </div>
                </Col>
              </Row>
            </div>
            <img src={images.btc} alt="" className="btctwo" />
            <img src={images.bannerpolygon} alt="" className="polygonone" />
            <img src={images.adrightimg} alt="" className="rightimg" />
            </>
            :""}
          </Container>
        </section>

        <section className="howitwork">
          <Container>
          {HowItWorks && HowItWorks.deleted ===1 ? 
          <> 
            <h2 className="headtitle">{HowItWorks.SectionId}</h2>
            <Row>
              <Col xl={6} lg={6} md={6} sm={12} xs={12}>
                <div className="workbox">
                  <h3 className="h3 text-green">{HowItWorks.SubHeading ? HowItWorks?.SubHeading[0]?.Heading1:""}</h3>
                  <ul className="worklist">
                    <li>
                      <span className="text-blue">
                      {HowItWorks.SubHeading ? HowItWorks?.SubHeading[1]?.Heading2:""}
                      </span>
                      <span className="workcontent">
                      {HowItWorks.Content? HowItWorks?.Content[0]?.Content1:""}
                      </span>
                    </li>
                    <li>
                      <span className="text-blue">{HowItWorks.SubHeading ? HowItWorks?.SubHeading[2]?.Heading3:""}</span>
                      <span className="workcontent">
                      {HowItWorks.Content? HowItWorks?.Content[1]?.Content2:""}
                      </span>
                    </li>
                    <li>
                      <span className="text-blue">
                      {HowItWorks.SubHeading ? HowItWorks?.SubHeading[3]?.Heading4:""}
                      </span>
                      <span className="workcontent">
                      {HowItWorks.Content? HowItWorks?.Content[2]?.Content3:""}
                      </span>
                    </li>
                    <li>
                      <span className="text-blue">
                      {HowItWorks.SubHeading ? HowItWorks?.SubHeading[4]?.Heading5:""}
                      </span>
                      <span className="workcontent">
                      {HowItWorks.Content? HowItWorks?.Content[3]?.Content4:""}
                      </span>
                    </li>
                    <li className="mt-50">
                      <span className="workcontent">
                      {HowItWorks.Content? HowItWorks?.Content[4]?.Content5:""}
                      </span>
                    </li>
                    <li>
                      <span className="workcontent">
                      {HowItWorks.Content? HowItWorks?.Content[5]?.Content6:""}
                      </span>
                    </li>
                    <li>
                      <span className="workcontent">
                      {HowItWorks.Content? HowItWorks?.Content[6]?.Content7:""}
                      </span>
                    </li>
                  </ul>
                </div>
              </Col>
              <Col xl={6} lg={6} md={6} sm={12} xs={12} className="text-center">
                <img src={`${config.Img}Images/cms/${HowItWorks.Tag1}`} alt="" className="workimg" />
              </Col>
            </Row>
            <ul className="bottomlist">
              <li>
                <span className="text-green bottomtext">
                {HowItWorks.SubHeading ? HowItWorks?.SubHeading[5]?.Heading6:""}
                </span>
              </li>
              <li>
                <span className="text-green bottomtext">
                {HowItWorks.SubHeading ? HowItWorks?.SubHeading[6]?.Heading7:""}
                </span>
              </li>
              <li>
                <span className="text-green bottomtext">
                {HowItWorks.SubHeading ? HowItWorks?.SubHeading[7]?.Heading8:""}
                </span>
              </li>
            </ul>
            <img src={images.workleftimg} alt="" className="workleftimg" />
            <img src={images.workrightimg} alt="" className="workrightimg" />
            </>:""}
          </Container>
        </section>

        <section className="roadmap" id = "Roadmap">
          <Container>
          {roadmap && roadmap.deleted ===1 ?  
          <>
            <h2 className="headtitle">{roadmap.FindAMillTitle}</h2>
            <h5 className="subhead">
             {roadmap.FindAMillHeading}
            </h5>
            <div className="roadmaptop">
              <div className="roadmapbox">
                <div className="roadline">
                  <div className="roadmapbtn">
                    <span>{roadmap.RoadMapButton1}</span>
                  </div>
                </div>
                <div>
                  <img src={images.diamond} alt="" />
                </div>
                <div>
                  <div className="roadinnerbox">
                    <p className="roadcontent">
                    {roadmap.RoadMapContent1?.map((RoadMapContent1)=><li>{RoadMapContent1}</li>)}
                    </p>
                  </div>
                </div>
              </div>
              <div className="roadmapbox">
                <div>
                  <div className="roadinnerbox">
                    <p className="roadcontent">
                    {roadmap.RoadMapContent2?.map((RoadMapContent2)=><li>{RoadMapContent2}</li>)}
                    </p>
                  </div>
                </div>
                <div>
                  <img src={images.diamond} alt="" />
                </div>
                <div className="roadline">
                  <div className="roadmapbtn">
                    <span>{roadmap.RoadMapButton2}</span>
                  </div>
                </div>
              </div>
              <div className="roadmapbox">
                <div className="roadline">
                  <div className="roadmapbtn">
                    <span>{roadmap.RoadMapButton3}</span>
                  </div>
                </div>
                <div>
                  <img src={images.diamond} alt="" />
                </div>
                <div>
                  <div className="roadinnerbox">
                    <p className="roadcontent">
                    {roadmap.RoadMapContent3?.map((RoadMapContent3)=><li>{RoadMapContent3}</li>)}
                    </p>
                  </div>
                </div>
              </div>
              <div className="roadmapbox">
                <div>
                  <div className="roadinnerbox">
                    <p className="roadcontent">
                    {roadmap.RoadMapContent4?.map((RoadMapContent4)=><li>{RoadMapContent4}</li>)}
                    </p>
                  </div>
                </div>
                <div>
                  <img src={images.diamond} alt="" />
                </div>
                <div className="roadline">
                  <div className="roadmapbtn">
                    <span>{roadmap.RoadMapButton4}</span>
                  </div>
                </div>
              </div>
              <div className="roadmapbox">
                <div className="roadline">
                  <div className="roadmapbtn">
                    <span>{roadmap.RoadMapButton5}</span>
                  </div>
                </div>
                <div>
                  <img src={images.diamond} alt="" />
                </div>
                <div>
                  <div className="roadinnerbox">
                    <p className="roadcontent">
                    {roadmap.RoadMapContent5?.map((RoadMapContent5)=><li>{RoadMapContent5}</li>)}
                    </p>
                  </div>
                </div>
              </div>
              <div className="roadmapbox">
                <div>
                  <div className="roadinnerbox">
                    <p className="roadcontent">
                    {roadmap.RoadMapContent6?.map((RoadMapContent6)=><li>{RoadMapContent6}</li>)}
                    </p>
                  </div>
                </div>
                <div>
                  <img src={images.diamond} alt="" />
                </div>
                <div className="roadline">
                  <div className="roadmapbtn">
                    <span>{roadmap.RoadMapButton6}</span>
                  </div>
                </div>
              </div>
            </div>
            <img src={images.Picture2} alt="" className="roadimgleft" />
            <img src={images.tether} alt="" className="tether" />
            <img src={images.tron} alt="" className="tron" />
            </>:""}
          </Container>
        </section>

        <section className="business">
          <Container>
          {business && business.deleted ===1 ?  
          <>
            <h2 className="headtitle">{business.SectionId}</h2>
            <Row>
              <Col xl={6} lg={6} md={6} sm={12} xs={12}>
                <div className="businessbox">
                  <div>
                    <h4 className="h4">{business.SubHeading ? business?.SubHeading[0]?.Heading1:""}</h4>
                    <p className="bscontent">
                    {business.Content? business?.Content[0]?.Content1:""}
                    </p>
                  </div>
                  <div>
                    <img src={`${config.Img}Images/cms/${business.Tag1}`}  alt="" />
                  </div>
                </div>
              </Col>
              <Col xl={6} lg={6} md={6} sm={12} xs={12}>
                <div className="businessbox">
                  <div>
                    <h4 className="h4">{business.SubHeading ? business?.SubHeading[1]?.Heading2:""}</h4>
                    <p className="bscontent">
                    {business.Content? business?.Content[1]?.Content2:""}
                    </p>
                  </div>
                  <div>
                    <img src={`${config.Img}Images/cms/${business.Tag2}`} alt="" />
                  </div>
                </div>
              </Col>
              <Col xl={6} lg={6} md={6} sm={12} xs={12}>
                <div className="businessbox">
                  <div>
                    <h4 className="h4">{business.SubHeading ? business?.SubHeading[2]?.Heading3:""}</h4>
                    <p className="bscontent">
                    {business.Content? business?.Content[2]?.Content3:""}
                    </p>
                  </div>
                  <div>
                    <img src={`${config.Img}Images/cms/${business.Tag3}`} alt="" />
                  </div>
                </div>
              </Col>
              <Col xl={6} lg={6} md={6} sm={12} xs={12}>
                <div className="businessbox">
                  <div>
                    <h4 className="h4">{business.SubHeading ? business?.SubHeading[3]?.Heading4:""}</h4>
                    <p className="bscontent">
                    {business.Content? business?.Content[3]?.Content4:""}
                    </p>
                  </div>
                  <div>
                    <img src={`${config.Img}Images/cms/${business.Tag4}`} alt="" />
                  </div>
                </div>
              </Col>
              <Col xl={6} lg={6} md={6} sm={12} xs={12}>
                <div className="businessbox">
                  <div>
                    <h4 className="h4">{business.SubHeading ? business?.SubHeading[4]?.Heading5:""}</h4>
                    <p className="bscontent">
                    {business.Content? business?.Content[4]?.Content5:""}
                    </p>
                  </div>
                  <div>
                    <img src={`${config.Img}Images/cms/${business.Tag5}`} alt="" />
                  </div>
                </div>
              </Col>
              <Col xl={6} lg={6} md={6} sm={12} xs={12}>
                <div className="businessbox">
                  <div>
                    <h4 className="h4">{business.SubHeading ? business?.SubHeading[5]?.Heading6:""}</h4>
                    <p className="bscontent">
                    {business.Content? business?.Content[5]?.Content6:""}
                    </p>
                  </div>
                  <div>
                    <img src={`${config.Img}Images/cms/${business.Tag6}`} alt="" />
                  </div>
                </div>
              </Col>
            </Row>
            <img src={images.leftboximg} alt="" className="bsleftimg" />
            <img src={images.bannerbinance} alt="" className="binance" />
            </>:""}
          </Container>
        </section>

        <section className="invest">
          <Container>
          {investment && investment.deleted ===1 ?  
          <>
            <h2 className="headtitle">{investment.SectionId}</h2>
            <h5 className="subhead">{investment.Content? investment?.Content[4]?.Content5:""}</h5>
            <div className="investtop">
              <Row>
                <Col xl={3} lg={3} md={6} sm={6} xs={12}>
                  <div className="investbox">
                    <h2 className="h2">{investment.SubHeading ? investment?.SubHeading[0]?.Heading2:""}</h2>
                    <h5 className="h5">{investment.Content? investment?.Content[0]?.Content1:""}</h5>
                  </div>
                </Col>
                <Col xl={3} lg={3} md={6} sm={6} xs={12}>
                  <div className="investbox">
                    <h2 className="h2">{investment.SubHeading ? investment?.SubHeading[1]?.Heading3:""}</h2>
                    <h5 className="h5">{investment.Content? investment?.Content[1]?.Content2:""}</h5>
                  </div>
                </Col>
                <Col xl={3} lg={3} md={6} sm={6} xs={12}>
                  <div className="investbox">
                    <h2 className="h2">{investment.SubHeading ? investment?.SubHeading[2]?.Heading4:""}</h2>
                    <h5 className="h5">{investment.Content? investment?.Content[2]?.Content3:""}</h5>
                  </div>
                </Col>
                <Col xl={3} lg={3} md={6} sm={6} xs={12}>
                  <div className="investbox">
                    <h2 className="h2">{investment.SubHeading ? investment?.SubHeading[3]?.Heading5:""}</h2>
                    <h5 className="h5">{investment.Content? investment?.Content[3]?.Content4:""}</h5>
                  </div>
                </Col>
              </Row>
              <div>
                <img src={images.coinsone} alt="" className="coinsone" />
                <img src={images.coinstwo} alt="" className="coinstwo" />
              </div>
            </div>
            <img src={images.investright} alt="" className="investrightimg" />
            </>:""}
          </Container>
        </section>

        <section className="posibility">
          <Container>
          {possibilities && possibilities.deleted ===1 ?  
          <>
            <h2 className="headtitle">{possibilities.SectionId}</h2>
            <Row>
              <Col xl={4} lg={4} md={6} sm={6} xs={12}>
                <div className="posbox">
                  <div>
                    <img src={`${config.Img}Images/cms/${possibilities.Tag1}`} alt="" />
                  </div>
                  <div>
                    <h5 className="h5">{possibilities.SubHeading ? possibilities?.SubHeading[0]?.Heading1:""}</h5>
                  </div>
                </div>
              </Col>
              <Col xl={4} lg={4} md={6} sm={6} xs={12}>
                <div className="posbox">
                  <div>
                    <img src={`${config.Img}Images/cms/${possibilities.Tag2}`}alt="" />
                  </div>
                  <div>
                    <h5 className="h5">{possibilities.SubHeading ? possibilities?.SubHeading[1]?.Heading2:""}</h5>
                  </div>
                </div>
              </Col>
            </Row>
            <img src={images.adrightimg} alt="" className="posrightimg" />
            </>:""}
          </Container>
        </section>

        <section className="team">
          <Container>
          {team && team.deleted ===1 ? 
          <>
            <h2 className="headtitle">{team.SectionId}</h2>
            <div className="teamtop">
              <Row>
                <Col xl={3} lg={3} md={6} sm={6} xs={12}>
                  <div className="teambox">
                    <div className="teamimg">
                      <img src={`${config.Img}Images/cms/${team.Tag1}`} alt="" />
                    </div>
                    <div className="teamdet">
                      <h5 className="h5">{team.SubHeading ? team?.SubHeading[0]?.Heading1:""} </h5>
                      <span>{team.Content? team?.Content[0]?.Content1:""}</span>
                    </div>
                  </div>
                </Col>
                <Col xl={3} lg={3} md={6} sm={6} xs={12}>
                  <div className="teambox">
                    <div className="teamimg">
                      <img src={`${config.Img}Images/cms/${team.Tag2}`}  alt="" />
                    </div>
                    <div className="teamdet">
                      <h5 className="h5">{team.SubHeading ? team?.SubHeading[1]?.Heading2:""} </h5>
                      <span>{team.Content? team?.Content[1]?.Content2:""}</span>
                    </div>
                  </div>
                </Col>
                <Col xl={3} lg={3} md={6} sm={6} xs={12}>
                  <div className="teambox">
                    <div className="teamimg">
                      <img src={`${config.Img}Images/cms/${team.Tag3}`}  alt="" />
                    </div>
                    <div className="teamdet">
                      <h5 className="h5">{team.SubHeading ? team?.SubHeading[2]?.Heading3:""} </h5>
                      <span>{team.Content? team?.Content[2]?.Content3:""}</span>
                    </div>
                  </div>
                </Col>
                <Col xl={3} lg={3} md={6} sm={6} xs={12}>
                  <div className="teambox">
                    <div className="teamimg">
                      <img src={`${config.Img}Images/cms/${team.Tag4}`}  alt="" />
                    </div>
                    <div className="teamdet">
                      <h5 className="h5">{team.SubHeading ? team?.SubHeading[3]?.Heading4:""} </h5>
                      <span>{team.Content? team?.Content[3]?.Content4:""}</span>
                    </div>
                  </div>
                </Col>
              </Row>
              <img src={images.bannerbinance} alt="" className="binancetwo" />
            </div>
            <img src={images.tron} alt="" className="trontwo" />
            <img src={images.workrightimg} alt="" className="workrightimgone" />
            </>:""}
          </Container>
        </section>

        <section className="conclusion">
          <Container>
          {conclusion && conclusion.deleted ===1 ? 
          <>
            <div className="lastbox">
              <Row>
                <Col xl={6} lg={6} md={12} sm={12} xs={12}>
                  <h2 className="headtitle">{conclusion.SectionId}</h2>
                  <p className="paracontent">
                  {conclusion.Content? conclusion?.Content[0]?.Content1:""}
                  </p>
                </Col>
                <Col
                  xl={6}
                  lg={6}
                  md={12}
                  sm={12}
                  xs={12}
                  className="text-center"
                >
                  <img src={`${config.Img}Images/cms/${conclusion.Tag1}`} alt="" className="earthimg" />
                </Col>
              </Row>
            </div>
            <img src={images.Picture2} alt="" className="picturethree" />
            <img src={images.tether} alt="" className="tetherone" />
           </> :""}
          </Container>
        </section>

        <Footer />
      </article>
    </>
  );
};

export default Home;
