const images = {
    logo: require("../src/Assets/images/logo.png"),
    bannerbtc: require("../src/Assets/images/banner-btc.png"),
    bannerpolygon: require("../src/Assets/images/banner-polygon.png"),
    bannertron: require("../src/Assets/images/banner-tron.png"),
    bannerstar: require("../src/Assets/images/banner-star.png"),
    bannerspheres: require("../src/Assets/images/banner-spheres.png"),
    bannerbinance: require("../src/Assets/images/banner-binance.png"),
    bannertether: require("../src/Assets/images/banner-tether.png"),
    bannereth: require("../src/Assets/images/banner-eth.png"),
    bannerpentagram: require("../src/Assets/images/banner-pentagram.png"),
    leftboximg: require("../src/Assets/images/leftbox.png"),
    proimgone: require("../src/Assets/images/proimg-1.png"),
    proimgtwo: require("../src/Assets/images/proimg-2.png"),
    proimgthree: require("../src/Assets/images/proimg-3.png"),
    proimgfour: require("../src/Assets/images/proimg-4.png"),
    millstar: require("../src/Assets/images/millstar.png"),
    millimg: require("../src/Assets/images/millimg.png"),
    diamond: require("../src/Assets/images/diamond.png"),
    millrightimg: require("../src/Assets/images/millrightimg.png"),
    Picture2: require("../src/Assets/images/Picture2.png"),
    rotatecoin: require("../src/Assets/images/rotatecoin.png"),
    btc: require("../src/Assets/images/btc.png"),
    adrightimg: require("../src/Assets/images/Picture1.png"),
    workimg: require("../src/Assets/images/workimg.png"),
    workleftimg: require("../src/Assets/images/workleftimg.png"),
    workrightimg: require("../src/Assets/images/workrightimg.png"),
    tether: require("../src/Assets/images/tether.png"),
    tron: require("../src/Assets/images/tron.png"),
    bsone: require("../src/Assets/images/bsone.png"),
    bstwo: require("../src/Assets/images/bstwo.png"),
    bsthree: require("../src/Assets/images/bsthree.png"),
    bsfour: require("../src/Assets/images/bsfour.png"),
    bsfive: require("../src/Assets/images/bsfive.png"),
    bssix: require("../src/Assets/images/bssix.png"),
    coinsone: require("../src/Assets/images/coinsone.png"),
    coinstwo: require("../src/Assets/images/coinstwo.png"),
    investright: require("../src/Assets/images/investright.png"),
    posone: require("../src/Assets/images/pos-one.png"),
    postwo: require("../src/Assets/images/pos-two.png"),
    teamone : require("../src/Assets/images/teamone.png"),
    teamtwo : require("../src/Assets/images/teamtwo.png"),
    teamthree : require("../src/Assets/images/teamthree.png"),
    teamfour : require("../src/Assets/images/teamfour.png"),
    earth: require("../src/Assets/images/earth.png"),
    bannervideo: require("../src/Assets/images/banner-video.gif"),
    whatsapp: require("../src/Assets/images/whatsapp.png"),
    telegram: require("../src/Assets/images/telegram.png"),
    gmail: require("../src/Assets/images/gmail.png"),
    arrow: require("../src/Assets/images/arrow.png"),
    twitter : require("../src/Assets/images/twitter.png")
}

export default images;